<template>
    <div style="max-width: 80px; max-height: 80px">
        <a v-if="typeof columnData === 'string'" :href="columnData" target="_blank"><img :src="columnData" alt="Image"/></a>
    </div>
</template>

<script>
export default {
    props: {
        columnData: String,
    }
}
</script>

<style scoped>

</style>
